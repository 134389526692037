.ImageCarousel {
  //width: 100vw;
  max-width: 70%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .carousel {
    max-width: 1024px;

    .carousel-item {
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
  }

  .textContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    & > * {
      margin: unset;
    }
  }

  @media screen and (max-width: 600px) {
    padding: unset;
    max-width: unset;

    .textContainer {
      padding: 0 0.2em;
      flex-direction: column;
      align-items: flex-start;
    }
  }

}